// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import {getDatabase} from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDwhybGV01eVHNx5DTKWLSbCe-cYvL5p18",
  authDomain: "bakalarka-8bdbb.firebaseapp.com",
  projectId: "bakalarka-8bdbb",
  storageBucket: "bakalarka-8bdbb.appspot.com",
  messagingSenderId: "607356537300",
  appId: "1:607356537300:web:09437815081055284b6e41",
  measurementId: "G-EZK2PS1MTE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


export const db = getDatabase(app);

