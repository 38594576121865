
export const normalFormColor = {
    "1NF" : "#f44336",
    "2NF" : "#ff9800",
    "3NF" : "#2C8F30",
    "BCNF" : "#B0FEB0",
    "practice" : "#ffffff"
};


export const password = "123456"; // Administrator's Password

export const colectionName = "problems"; // Name of the collection in the FireStore database


