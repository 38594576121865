import React, { useState, useEffect } from 'react'
import { Algorithm } from '../../../../../algorithm/Algorithm';
import { NormalFormALG } from '../../../../../algorithm/NormalFormALG';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAttributeContext } from '../../../../../contexts/AttributeContext';
import { useDependencyContext } from '../../../../../contexts/DependencyContext';
import Swal from 'sweetalert2';
import { normalFormColor } from '../../../../../constantValues/constantValues';
import { useTranslation } from 'react-i18next';
import './mergeTablesAfterDecompose.scss';

function MergeTablesAfterDecompose({ tables, originKeys, lostFDs }) {

  const { t } = useTranslation();
  const algoInstance = new Algorithm();
  const normalFormInstance = new NormalFormALG();

  const { attributes } = useAttributeContext();
  const { dependencies, setDependencies } = useDependencyContext();

  const [tablesInfo, setTablesInfo] = useState(tables);
  const [lostFDsInfo, setLostFDsInfo] = useState(lostFDs);
  const [draggingOverIndex, setDraggingOverIndex] = useState(null);
  const [draggingItemIndex, setDraggingItemIndex] = useState(null);

  const fPlus = algoInstance.FPlus(dependencies, attributes);
  const singleRHS_fPlus = algoInstance.rewriteFDSingleRHS(fPlus);

  useEffect(() => {
    setTablesInfo(tables);
    setLostFDsInfo(lostFDs);
  }, [tables, lostFDs]);

  const getValidDependenciesFromFplus = (attr) => {
    let dependenciesDependsOnAttr = [];
    for (let i = 0; i < singleRHS_fPlus.length; i++) {
      let leftSideValid = singleRHS_fPlus[i].left.every(element => attr.includes(element));
      let rightSideValid = singleRHS_fPlus[i].right.every(element => attr.includes(element));

      if (leftSideValid && rightSideValid) {
        dependenciesDependsOnAttr.push(singleRHS_fPlus[i]);
      }
    }
    return dependenciesDependsOnAttr;
  };

  function mergeTables(table1, table2) {
    const mergedAttributes = Array.from(new Set([...table1.data.originalAttr, ...table2.data.originalAttr]));
    const FDs = getValidDependenciesFromFplus(mergedAttributes);
    const keys = algoInstance.getAllKeys(FDs, mergedAttributes);
    const normalForm = normalFormInstance.normalFormType(FDs, mergedAttributes);

    const mergedTable = {
      id: table1.id,
      data: {
        originalAttr: mergedAttributes,
        candidateKeys: keys,
        FDs: FDs,
        type: normalForm.type,
        faultyDependencies: normalForm.faultyDependencies
      }
    };

    return mergedTable;
  }

  const onDragTableStart = (start) => {
    setDraggingItemIndex(start.source.index);
    setDraggingOverIndex(null);
  };

  const onDragUpdate = (update) => {
    setDraggingOverIndex(update.destination ? update.destination.index : null);
  };

  const onDragEndTables = (result) => {
    const { source, destination } = result;
    setDraggingOverIndex(null);
    setDraggingItemIndex(null);

    if (!destination || destination.index === source.index) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index !== destination.index) {
      const sourceItem = tablesInfo[source.index];
      const destinationItem = tablesInfo[destination.index];

      if ( algoInstance.subset(sourceItem.data.originalAttr, destinationItem.data.originalAttr)
           || algoInstance.subset(destinationItem.data.originalAttr, sourceItem.data.originalAttr)
           || sourceItem.data.candidateKeys.some((K1) =>
                destinationItem.data.candidateKeys.some((K2) =>
                  algoInstance.isDependencyInClosure(singleRHS_fPlus, K1, K2)
                  && algoInstance.isDependencyInClosure(singleRHS_fPlus, K2, K1) 
                  )
                ) ) {

        const mergedValue = mergeTables(sourceItem, destinationItem);

        if (mergedValue.data.type === 'BCNF' || mergedValue.data.type === '3') {
          const newTablesInfo = [...tablesInfo];
          newTablesInfo[destination.index] = mergedValue;
          newTablesInfo.splice(source.index, 1);
          setTablesInfo(newTablesInfo);

          let tablesFDs = [];
          newTablesInfo.forEach(table => {
            tablesFDs.push(...table.data.FDs);
          });
          const newFplus = algoInstance.FPlus(tablesFDs, attributes);
          const newFplusSingleRHS = algoInstance.rewriteFDSingleRHS(newFplus);
          let newLostFDs = [...lostFDsInfo];
          newLostFDs.forEach(fd => {
            const attrClosure = algoInstance.attributeClosure(newFplusSingleRHS, fd.left);
            if(attrClosure.includes(fd.right[0])){
               newLostFDs = newLostFDs.filter(item => item !== fd);
            };
          });
          setLostFDsInfo(newLostFDs);

          Swal.fire({
            icon: 'success',
            title: t('problem-synthesis.TablesAreMergedTitle'),
            text: t('problem-synthesis.TablesAreMergedSuccessfully'),
          });

        } else {
          Swal.fire({
            icon: 'error',
            title: t('problem-synthesis.CanNotMerge'),
            text: t('problem-synthesis.CanNotMerge-reason1', { normalForm: mergedValue.data.type }),
          });
        }

      } else {
        Swal.fire({
          icon: 'error',
          title: t('problem-synthesis.CanNotMerge'),
          text: t('problem-synthesis.CanNotMerge-reason2'),
        });
      }
    }
  };

  const getAllDependenciesDependsOnAttr = (attr) => {
    let dependenciesDependsOnAttr = [];
    for (let i = 0; i < singleRHS_fPlus.length; i++) {
      // Zkontrolujeme, že všechny prvky na levé i pravé straně jsou obsaženy v `attr`
      let leftSideValid = singleRHS_fPlus[i].left.every(element => attr.includes(element));
      let rightSideValid = singleRHS_fPlus[i].right.every(element => attr.includes(element));
  
      if (leftSideValid && rightSideValid) {
        dependenciesDependsOnAttr.push(singleRHS_fPlus[i]);
      }
    }

    return dependenciesDependsOnAttr;
  };

  const nodeData = (attr) => {
    const fPlus = getAllDependenciesDependsOnAttr(attr);
    const normalFormType = normalFormInstance.normalFormType(fPlus, attr);
    const candidateKeys = algoInstance.getAllKeys(fPlus, attr);
    let data = {
      originalAttr: attr,
      label: attr.join(", "),
      keys: algoInstance.showKeysAsText(candidateKeys),
      FDs: fPlus,
      type: normalFormType.type,
      faultyDependencies: normalFormType.faultyDependencies,
      candidateKeys: candidateKeys,
      subsetOf: [],
    };

    return data;
  };

  const addLostDependency = (fd) => {
    const dataNode = nodeData([...fd.left, ...fd.right]);
    const position = { x: 0, y: 0 };
    const newNode = {
      id: dataNode.label,
      type: 'customNode',
      data: dataNode,
      position,
      };
    const newTablesInfo = [...tablesInfo];
    newTablesInfo.push(newNode);
    setTablesInfo(newTablesInfo);

    let tablesFDs = [];
    newTablesInfo.forEach(table => {
      tablesFDs.push(...table.data.FDs);
    });
    const newFplus = algoInstance.FPlus(tablesFDs, attributes);
    const newFplusSingleRHS = algoInstance.rewriteFDSingleRHS(newFplus);
    let newLostFDs = [...lostFDsInfo];
    newLostFDs.forEach(fd => {
      const attrClosure = algoInstance.attributeClosure(newFplusSingleRHS, fd.left);
      if(attrClosure.includes(fd.right[0])){
         newLostFDs = newLostFDs.filter(item => item !== fd);
      };
    });
    setLostFDsInfo(newLostFDs);
  };

  const tableBackgroundColor = (type, practiceMode) => {
    if (practiceMode) {
      return normalFormColor.practice; // White color when in practice mode
    }

    if (type === 'BCNF') {
      return normalFormColor.BCNF;
    } else if (type === '3') {
      return normalFormColor['3NF'];
    } else if (type === '2') {
      return normalFormColor['2NF'];
    } else {
      return normalFormColor['1NF'];
    }
  };

     // Přidání logiky pro označení nadbytečných tabulek
     const markRedundantTables = () => {
      return tablesInfo.map((table, index) => {
        let isSubset = false;
        let longestSubsets = [];
        let maxLength = 0;

        tablesInfo.forEach((otherTable, otherIndex) => {
          if (index !== otherIndex 
              && algoInstance.subset(table.data.originalAttr, otherTable.data.originalAttr)
              && (!algoInstance.subset(otherTable.data.originalAttr, table.data.originalAttr) || (index > otherIndex))) {
            isSubset = true;
            const length = otherTable.data.originalAttr.length;
            if (length > maxLength) {
              maxLength = length;
              longestSubsets = [otherTable.data.originalAttr];
            } else if (length === maxLength) {
              longestSubsets.push(otherTable.data.originalAttr);
            }
          }
        });

        return {
          ...table,
          isSubset,
          subsetOf: longestSubsets
        };
      });
    };



  const enrichedTablesInfo = markRedundantTables();
  
  return (
    <div className='mergeTablesAfterDecompose-container'>
      <div className='Tables'>
        <DragDropContext onDragStart={onDragTableStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEndTables}>
          <Droppable droppableId="droppableTables">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  enrichedTablesInfo.map((table, index) => (
                    <Draggable key={table.id} draggableId={`table${table.id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`table ${table.isSubset ? 'SubsetTable' : ''}`}
                          style={{
                            userSelect: 'none',
                            padding: 16,
                            margin: '0 0 8px 0',
                            backgroundColor: index === draggingOverIndex && index !== draggingItemIndex ? '#ccc' : tableBackgroundColor(table.data.type, false/*practiceMode*/),
                            border: '1px solid #ddd',
                            transform: snapshot.isDragging ? provided.draggableProps.style.transform : 'none',
                          }}
                        >
                          <div>
                            <p className='tableAttrs'>({table.data.originalAttr.join(',')})</p>
                            <p className='tableKeys'>
                              {t('problem-synthesis.keys')}: [ {algoInstance.showKeysAsText(table.data.candidateKeys)} ]
                            </p>
                            {table.isSubset && (
                              <p>
                                {<span className='note' style={{ color: 'red' }}>{t('mergeTablesAfterDecompose.isSubsetOf', {table: algoInstance.showKeysAsText(table.subsetOf)})}</span>}
                              </p>
                            )}

                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className='modal-middle'>
        <b>{t('problem-decomposition.lostDependencies')}</b>
        <div className='lostDependencies'>
        {lostFDsInfo.length > 0 ? (
                <ul>
                  {lostFDsInfo.map((fd, index) => {
                    const typeNF = normalFormInstance.normalFormType(algoInstance.FPlus(dependencies, [...fd.left, ...fd.right]), [...fd.left, ...fd.right]).type;
                    return (
                      <li key={index}>
                        {algoInstance.showTextDependencyWithArrow(fd)}
                        <button
                          className='addButton'
                          style={{backgroundColor: tableBackgroundColor(typeNF, false/*practiceMode*/), border: 'none'}}
                          onClick={() => addLostDependency(fd)}>
                          {t('mergeTablesAfterDecompose.addRelation')}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
            <p>{t('problem-decomposition.noLostDependencies')}</p>
          )}
        </div>
      </div>


    </div>
  )
}

export default MergeTablesAfterDecompose;
